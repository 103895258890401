.header {
  display: none;
  background-color: var(--color-white);
  margin-bottom: 2rem;
  padding: 2rem 0;
  border-bottom: .1rem solid var(--color-gray-lightest);
}

.header__wrapper {
  max-width: 768px;
  margin: 0 auto;
}

.header .btn--logo {
  display: flex;
  align-items: center;
  font-size: 2.8rem;
  font-weight: bold;
  font-family: var(--font-proxima);
}

.header .btn--logo img {
  width: 4.8rem;
  box-sizing: content-box;
  padding-right: 1.2rem;
  margin-right: 1.2rem;
  border-right: .2rem solid var(--color-black);
}

@media screen and (min-height: 700px) {
  .header {
    margin-bottom: 4rem;
  }
}

@media screen and (min-width: 768px) {
  .header {
    display: block;
  }
}

@media screen and (min-width: 868px) {
  .header .btn--logo img {
    margin-left: -4.8rem;
  }
}
