.banner {
  margin-bottom: 3rem;
}

.banner-head {
  display: grid;
  grid-template-columns: 1fr 2fr;
}

.banner-head__image {
  width: 75%;
  border-radius: 50%;
  margin: 0 auto;
}

.banner-head__info {
  align-self: center;
}

.banner-head__username {
  font-weight: normal;
  font-size: 15px;
  margin: 0;
}

.banner-head__group {
  display: flex;
}

.banner-head__group__separator {
  margin: 0 .4rem;
}

.banner-head .input--inline .input__tag {
  width: 10rem;
  font-weight: bold;
}

.banner-edit {
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: center;
  grid-gap: 1rem;
  margin-top: 1.4rem;
}

.banner-progress {
  margin-top: 1.4rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: .5rem;
}

.banner-progress__line {
  height: .1rem;
  border: .1rem solid var(--color-gray-lighter);
}

.banner-progress__line--active {
  background-color: var(--color-black);
  border-color: var(--color-black);
}
