.btn {
  appearance: none;
  outline: none;
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  text-decoration: none;
  cursor: pointer;
  color: var(--color-black);
  font: inherit;
  display: inline-block;
}
.btn:visited {
  color: var(--color-black);
}

.btn--pill {
  font-size: 1.2rem;
  border-radius: 2.4rem;
  padding: 1.6rem 2rem;;
  color: var(--color-white);
  background-color: var(--color-black);
}
.btn--pill:visited {
  color: var(--color-white);
}
.btn--pill + .btn--pill {
  margin-top: 1.5rem;
}

.btn--pill-white {
  color: var(--color-black);
  background-color: var(--color-white);
  box-shadow: 0 .3rem .6rem hsla(0, 0%, 0%, 18%);
}
.btn--pill-white:visited {
  color: var(--color-black);
}

.btn--square-white {
  font-size: 1.2rem;
  background-color: var(--color-white);
  border-radius: .4rem;
  padding: .4rem;
  border: .1rem solid var(--color-gray);
}

.btn--wide {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  text-align: center;
}
