.partner-link {
  margin-bottom: .8rem;
}

.partner-link .input {
  margin-bottom: 4.2rem;
}

.partner-link .btn--pill {
  max-width: 24rem;
}

@media screen and (min-width: 768px) {
  .partner-link .btn--pill {
    max-width: 36rem;
  }
}
