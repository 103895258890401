.timetable-hours {
  display: flex;
  margin: 0 auto;
  max-width: 40rem;
}

.timetable-hours__divider {
  margin: auto;
  align-self: center;
  font-size: 1.7rem;
}

.timetable-hours .incrementor {
  flex: 0 1 16rem;
}

@media screen and (min-width: 768px) {
  .timetable-hours__divider {
    font-size: 1.9rem;
  }
}
