.wrapper {
  margin: 0 auto;
  width: var(--wrapper-width);
}

.wrapper--page {
  background-color: var(--wrapper-background-color);
  border: .1rem solid var(--wrapper-border-color);
  padding: var(--wrapper-padding);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

@media screen and (min-height: 600px) {
  .wrapper-vertical {
    position: absolute;
    left: 0;
    right: 0;
    top: calc(0px + env(safe-area-inset-top));
    bottom: calc(0px + env(safe-area-inset-bottom));

    display: flex;
    flex-direction: column;

    justify-content: top;
    padding-top: 3rem;
  }
}

@media screen and (min-width: 768px) {
  .wrapper-vertical {
    justify-content: center;
    padding-top: 0;
  }

  .wrapper--page {
    min-height: 65rem;
  }

  .wrapper--duo {
    display: grid;
    grid-template-columns: 1.6fr 1fr;
    grid-gap: 3rem;
  }
}
