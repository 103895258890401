.navigation {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  margin-top: auto;
  margin-bottom: 0;
}

.navigation .btn {
  height: 5rem;
}

.navigation .btn--arrow {
  width: 5rem;
  line-height: 0;
  padding: 0;
}

.navigation .btn--arrow img {
  width: 1.6rem;
}

.navigation__back {
  text-align: left;
}

.navigation__back .btn--arrow img {
  transform: rotate(180deg);
}

.navigation__middle {
  text-align: center;
}

.navigation__forward {
  text-align: right;
}
