.timetable-days {
  display: flex;
  margin: 0 auto;
  max-width: 45rem;
  position: relative;
}

.timetable-days .btn {
  font-size: 1.5rem;
  padding: 0;
  margin: auto;
  height: 4rem;
  width: 4rem;
}

.timetable-days .tooltip {
  position: absolute;
  top: -7rem;
  left: 0;
  width: 23rem;
}
