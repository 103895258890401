:root {
  --color-white: hsl(0, 0%, 100%);
  --color-black: hsl(0, 0%, 0%);

  --color-gray:	hsl(0, 0%, 15%);
  --color-gray-light:	hsl(0, 0%, 45%);
  --color-gray-lighter:	hsl(0, 0%, 60%);
  --color-gray-lightest:	hsl(0, 0%, 89%);
  --color-pink: hsl(337, 63%, 51%);
  --color-background: hsl(0, 0%, 98%);

  --font-family: Roboto, sans-serif;
  --font-proxima: 'Proxima Nova Black', Robot, sans-serif;
  --font-proxima-medium: 'Proxima Nova Medium', Robot, sans-serif;
  --font-size: 1.2rem;

  --heading-padding: 0;
  --heading-margin: 0 0 2rem 0;

  --wrapper-width: auto;
  --wrapper-background-color: transparent;
  --wrapper-padding: 1.8rem 1.2rem;

  --landing-padding: 2.4rem;
}

@media screen and (min-width: 768px) {
  :root {
    --heading-padding: 0 6rem;

    --wrapper-width: 768px;
    --wrapper-border-color: hsl(0, 0%, 93%);
    --wrapper-background-color: var(--color-white);
    --wrapper-padding: 2.4rem;
  }
}

@import "./base/_reset.css";
@import "./base/_typography.css";

@import "./components/_wrapper.css";
@import "./components/_button.css";
@import "./components/_input.css";
@import "./components/_heading.css";
@import "./components/_incrementor.css";
@import "./components/_tooltip.css";
@import "./components/_navigation.css";
@import "./components/_timetable-days.css";
@import "./components/_timetable-hours.css";
@import "./components/_timetable-slots.css";
@import "./components/_deposits-tiers.css";
@import "./components/_banner.css";
@import "./components/_partner-link.css";
@import "./components/_footer-contact.css";
@import "./components/_dashboard-metrics.css";
@import "./components/_header.css";
@import "./components/_page.css";
@import "./components/_video-modal.css";

@import "./layouts/_all-set.css";
@import "./layouts/_bank.css";
@import "./layouts/_contact-info.css";
@import "./layouts/_dashboard.css";
@import "./layouts/_google.css";
@import "./layouts/_landing.css";
@import "./layouts/_timetable.css";
