.timetable-slots {
  max-width: 35rem;
  margin: 0 auto;
}

.timetable-slots__slot {
  font-size: 1.5rem;
  display: grid;
  grid-template-columns: 1fr 1fr auto;
}

.timetable-slots .btn--del {
  line-height: 0;
}

.timetable-slots .btn--del img {
  width: 1.2rem;
  height: 1.2rem;
}
