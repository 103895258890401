.incrementor {
  text-align: center;
}

.incrementor__value {
  font-size: 3rem;
  text-transform: uppercase;
  padding: 3.5rem 0;
}

.incrementor .btn {
  margin: 0 auto;
  padding: .8rem 1.6rem;
  line-height: 0;
}

.incrementor .btn img {
  height: 1.6rem;
}

.incrementor .btn--down img {
  transform: rotate(180deg);
}

@media screen and (min-width: 768px) {
  .incrementor__value {
    font-size: 3.5rem;
  }
}
