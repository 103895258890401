html {
  font-size: 62.5%;
}

body {
  color: var(--color-black);
  background-color: var(--color-background);

  font-size: var(--font-size);
  font-family: var(--font-family);
  font-weight: normal;
}

@font-face {
  font-family: "Proxima Nova Black";
  src: url("../../fonts/aa46d234cf405126cdd1c36600e4b978.eot"); /* IE9*/
  src: url("../../fonts/aa46d234cf405126cdd1c36600e4b978.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("../../fonts/aa46d234cf405126cdd1c36600e4b978.woff2") format("woff2"), /* chrome、firefox */
  url("../../fonts/aa46d234cf405126cdd1c36600e4b978.woff") format("woff"), /* chrome、firefox */
  url("../../fonts/aa46d234cf405126cdd1c36600e4b978.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
  url("../../fonts/aa46d234cf405126cdd1c36600e4b978.svg#Proxima Nova Black") format("svg"); /* iOS 4.1- */
}

@font-face {
  font-family: "Proxima Nova Medium";
  src: url("../../fonts/6fc0ad4561b62243fd580b2e0e76d514.eot"); /* IE9*/
  src: url("../../fonts/6fc0ad4561b62243fd580b2e0e76d514.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("../../fonts/6fc0ad4561b62243fd580b2e0e76d514.woff2") format("woff2"), /* chrome、firefox */
  url("../../fonts/6fc0ad4561b62243fd580b2e0e76d514.woff") format("woff"), /* chrome、firefox */
  url("../../fonts/6fc0ad4561b62243fd580b2e0e76d514.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
  url("../../fonts/6fc0ad4561b62243fd580b2e0e76d514.svg#Proxima Nova Medium") format("svg"); /* iOS 4.1- */
}
