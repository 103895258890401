.footer-contact {
  margin: auto auto 0 auto;
  max-width: 24rem;
  font-size: 11px;
  color: var(--color-gray-lighter);
}

.footer-contact .btn--pill {
  margin-bottom: .8rem;
}

@media screen and (min-width: 768px) {
  .footer-contact {
    max-width: 36rem;
  }
}
