.landing-phones {
  display: none;
  position: relative;
}

.landing-phones__item {
  width: 60%;
  position: absolute;
  filter: drop-shadow(.2rem .2rem .7rem hsla(0, 0%, 0%, 50%));
}

.landing-phones__item:nth-child(1) {
  top: 0;
  left: 0;
}

.landing-phones__item:nth-child(2) {
  bottom: 0;
  right: 0;
}

.landing-phones__img,
.landing-phones__video {
  width: 100%;
  height: 100%;
}

.landing-phones__img--cutout {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.landing-phones__video {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  padding: 1.3rem 1.7rem 1.7rem 1.8rem;
}

.landing {
  text-align: center;
}

.landing__heading {
  font-size: 5rem;
  font-family: var(--font-proxima);
  margin: 0;
}

.landing__subheading {
  font-size: 1.6rem;
  font-family: var(--font-proxima-medium);
  font-weight: normal;
  padding: 0 var(--landing-padding);
  margin: .8rem 0;
}

.landing__demo {
  width: 100vw;
  position: relative;
  margin: 0 auto;
  margin-bottom: .8rem;
}

.landing__hand {
  width: 100%;
  max-width: 40rem;
}

.landing__learn-more {
  width: 5.2rem;
  height: 5.2rem;
  border-radius: 50%;
  font-size: 1rem;
  font-weight: bold;
  color: var(--color-white);
  background-color: var(--color-pink);
  padding: .2rem;
  position: absolute;
  right: 2rem;
  bottom: 2rem;
}

.landing .btn--pill {
  display: block;
  margin: 0 var(--landing-padding);
}

.landing__info {
  padding: 0 var(--landing-padding);
  font-size: 1.1rem;
  color: var(--color-gray-light);
}

@media screen and (min-width: 768px) {
  .landing__heading {
    padding-top: var(--landing-padding);
  }
  .landing-phones {
    display: block;
  }
  .landing {
    background-color: var(--color-white);
    box-shadow: 0 .3rem .6rem hsla(0, 0%, 0%, 8%);
  }
  .landing__demo {
    width: auto;
  }
  .landing__learn-more {
    display: none;
  }
}
