.deposits-tiers {
  max-width: 24rem;
  margin: 0 auto;
}

.deposits-tiers .btn--pill {
  width: 100%;
}

.deposits-tiers__tier {
  margin-bottom: .8rem;
}

.deposits-tiers__tier--disabled {
  opacity: 0.15;
}

.deposits-tiers__tier .input__tag {
  text-align: center;
  width: 100%;
}

.deposits-tiers__tier .input--offering .input__tag {
  margin-top: .4rem;
  font-size: 1.1rem;
  color: var(--color-gray-light);
}

@media screen and (min-width: 768px) {
  .deposits-tiers {
    max-width: 36rem;
  }
}
