.input .input__tag {
  appearance: none;
  outline: none;
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  text-decoration: none;
  color: var(--color-black);
  font: inherit;
  display: inline-block;
}

.input__label {
  font-size: 1.1rem;
  margin-bottom: .2rem;
  color: var(--color-gray-light);
  display: block;
}

.input--underline .input__tag {
  font-size: 1.4rem;
  border-bottom: .1rem solid var(--color-gray-lighter);
  padding: .2rem 0;
  width: 100%;
}
.input--underline + .input--underline {
  margin-top: 1rem;
}

.input--inline {
  display: inline;
}
.input--inline .input__tag {
  font-size: 1.3rem;
}

.input--pill {
  border-radius: 2.4rem;
  padding: 1.6rem 2rem;;
  background-color: var(--color-black);
  border: .1rem solid transparent;
}
.input--pill .input__tag {
  color: var(--color-white);
}

.input--pill-white {
  background-color: var(--color-white);
  border: .1rem solid var(--color-black);
}
.input--pill-white .input__tag {
  color: var(--color-black);
}
