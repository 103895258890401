.video-modal {
  position: absolute;
  top: 16rem;
  min-height: calc(100vh - 16rem);
  left: 0;
  right: 0;

  background-color: var(--color-black);
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;

  padding: 2.4rem 1.8rem;

  color: var(--color-white);
}

.video-modal__logo {
  width: 5rem;
  margin: 0 auto;
  display: block;

  filter: invert(1);
}

.video-modal__player {
  position: relative;

  margin: 1.8rem 8rem;
}

.video-modal__video,
.video-modal__phone--cutout {
  position: absolute;

  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  width: 100%;
  height: 100%;
}

.video-modal__video {
  padding: 5%;
  border-radius: 10%;
}

.video-modal__phone {
  width: 100%;
  max-width: 27vh;
  margin: 0 auto;
}

.video-modal__text {
  font-size: 1.5rem;
  font-family: var(--font-proxima-medium);
  font-weight: normal;
}

@media screen and (max-height: 600px) {
  .video-modal {
    top: 6rem;
    min-height: calc(100vh - 6rem);
  }
}

@media screen and (min-width: 768px) {
  .video-modal {
    display: none;
  }
}
