.heading {
  color: var(--color-gray-light);
  padding: var(--heading-padding);
  margin: var(--heading-margin);
  text-align: center;
}

.heading__image {
  width: 11rem;
}

.heading__title {
  margin: 0;
  margin-bottom: .8rem;
  font-size: 2.5rem;
  color: var(--color-black);
  text-align: center;
}

@media screen and (min-width: 768px) {
  .heading__title {
    margin-bottom: 3rem;
  }
}