.dashboard-metrics {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  max-width: 24rem;
  margin: 0 auto;
}

.dashboard-metrics__metric {
  text-align: center;
}

.dashboard-metrics__metric__value {
  font-size: 3.5rem;
  color: var(--color-gray-lighter);
}

@media screen and (min-width: 768px) {
  .dashboard-metrics {
    max-width: 36rem;
  }
}
